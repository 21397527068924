body,
html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  /*background-color: yellow; */
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

#container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  min-height: 100%;
  width: 0%;
  height: 100%;
  background-color: #607d8b;
  color: #000000;
}

.canvas-area {
  margin: 7px 0 0 7px;
  border: 1px solid #0d3349;
  background-color: #ffffff;
}

.tool-button {
  min-width: 120;
}
