@import url("https://fonts.googleapis.com/css?family=Boogaloo&display=swap");

html,
body {
  font-family: "Boogaloo", cursive;
  background-color: cyan;
  height: 100%;
}

.Home-bkg1 {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  background-color: cyan;
}

.Header1 {
  position: absolute;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: black;
  color: white;
  text-align: center;
}

.home{
  flex: 10%;
}

h1.homeIcon {
  float: left;
  margin-top: 0.3em;
  margin-left: 0.2em;
  font-size: 2em;
  position: absolute;
}

.Footer1 {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 7%;
  background-color: #464646;
  color: white;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .Title1 {
    font-size: 2.7em;
    /* margin-bottom: 10%; */
    /*margin-top: 1%;*/
  }
}

@media screen and (min-width: 700px) {
  .Title1 {
    font-size: 4.5em;
  }
  .Header1 {
    height: 7%;
  }
}

.FTitle1 {
  /*font-family: "Times New Roman", Times, serif;*/
  font-size: 1.5em;
  /*bottom: %;*/
  padding-top: 1%;
}

.Container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Center1 {
  width: 70%;
  position: absolute;
  top: 16%;
  text-align: center;
}

.menu {
  font-size: 2em;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
}
.card {
  float: none;
  margin: 0 auto;
  width: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 1em;
  margin-bottom: 1em;
}

@media screen and (max-width: 400px) {
  .card {
    margin-bottom: 100px;
    margin-top: 0;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .Center1 {
    margin-top: 0px;
  }
}

.Btn-Green1 {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 1em 2em;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin-right: 1%;
  margin-bottom: 1em;
}

.Btn-Green1:hover {
  background-color: grey;
}

.Field1 {
  width: 60%;
  height: 70px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 0.5px solid grey;
  font-size: 2em;
}

@media screen and (max-width: 400px) {
  .Field1 {
    font-size: 1.2em;
    width: 90%;
  }
}

.Heading1 {
  font-size: 2em;
  margin-bottom: 0;
}

.Sub-Heading1 {
  font-size: 1.5em;
  margin-top: 1px;
  margin-bottom: 2.2em;
}

@media screen and (max-width: 600px) {
  .Sub-Heading1 {
    font-size: 1em;
  }
}

.Green-Bar1 {
  position: absolute;
  width: 100%;
  top: 30%;
  font-size: 3em;
  background-color: rgb(8, 199, 8);
  text-align: center;
}

@media screen and (max-width: 600px) {
  .Green-Bar1 {
    top: 27%;
  }
}

@media screen and (min-width: 700px) {
  .Spacer1 {
    /*border: 2px solid red;*/
    height: 130px;
    top: 40%;
    font-size: 3em;
  }
}

@media screen and (max-width: 600px) {
  .Spacer1 {
    /*border: 2px solid red;*/
    height: 40px;
    top: 40%;
    font-size: 3em;
  }
}

.Field-Heading1 {
  font-size: 1.5em;
  margin-top: 10px;
}

.container1 {
  /*display: block;*/
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Btn-Submit1 {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 1em 1em;
  margin: none;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  margin-bottom: 1em;
  font-size: 2em;
  margin-top: 5px;
  line-height: 5px;
}

.Table {
  background-color: #ee959e;
  width: 120%;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  margin-inline-start: -10%;
  margin-bottom: 10px;
  text-align-last: center;
}
.Table-Name {
  text-align: left;
  width: 50%;
}
.Table-Answer {
  width: 10%;
}
.Table-Challenge {
  width: 10%;
}
.player {
  text-align: left;
  width: 30%;
}

.answer {
  width: 40%;
}
.score {
  width: 10%;
}

.Table-Entry {
  background-color: #f4c2c2;
  font-size: 1.5em;
  margin-inline-end: auto;
  margin-right: 0.8em;
  margin-left: 20px;
  margin-bottom: 5px;
  margin-top: 2px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  /*top: 0;
  left: 0;*/
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.homeLink {
  text-decoration: none;
  color: white;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.Timer {
  margin-left: 37%;
}
@media screen and (max-width: 400px) {
  .Timer {
    margin-left: 15%;
  }
}

.question-box {
  background-color: white;
  padding: 10px 20px;
  border: 10px solid rgb(253, 145, 102);
}
