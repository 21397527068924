@import url("https://fonts.googleapis.com/css?family=Boogaloo&display=swap");

body,
body * {
  font-family: "Boogaloo", cursive;
}

.Home-bkg {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  background-color: cyan;
}

.Header {
  position: absolute;
  top: 3%;
  width: 100%;
  height: 10%;
  background-color: black;
  color: white;
  text-align: center;
}
h1.homeIcon {
  float: left;
  margin-top: 0.2em;
  font-size: 3em;
  position: absolute;
}

.Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4%;
  background-color: #464646;
  color: white;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .Title {
    font-size: 3em;
    margin-bottom: 10%;
    /*margin-top: 1%;*/
  }
}

@media screen and (min-width: 700px) {
  .Title {
    font-size: 5em;
  }
}

.FTitle {
  /*font-family: "Times New Roman", Times, serif;*/
  font-size: 1em;
  /*bottom: %;*/
  padding-top: 1%;
}
.white-board-title {
  text-align: center;
  position: absolute;
  margin-left: 15%;
  margin-top: 25%;
}

.center-whiteboard {
  background-color: white;
  width: 300px;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  /*border: 2px solid red;*/
}

@media screen and (max-width: 600px) {
  .Center {
    margin-top: 55px;
  }
}

.Btn-Green {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 0.3em 2em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2em;
  margin-right: 0.5%;
}

.Btn-Green:hover {
  background-color: grey;
}

.Field {
  width: 99%;
  height: 50px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 0.5px solid grey;
  font-size: 2em;
}

@media screen and (max-width: 600px) {
  .Field {
    font-size: 1.5em;
  }
}
.homeLink {
  text-decoration: none;
  color: white;
}
.Link {
  color: #007bff;
  text-decoration: underline;
  font-size: 3em;
  background: none;
  border: none;
  cursor: pointer;
}

.Link:active {
  color: #4fa2fc;
  text-decoration: underline;
  font-size: 3em;
  background: none;
  border: none;
  /*color: red;*/
}

.Field-Heading {
  font-size: 1.5em;
  color: rgb(92, 92, 92);
}

.canvas {
  border: 1px solid black;
  background-color: white;
}
