@import url("https://fonts.googleapis.com/css?family=Boogaloo&display=swap");

body,
body * {
  font-family: "Boogaloo", cursive;
}

.Home-bkg {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  /* width: 100%; */
  width: 100vw;
  background-color: cyan;
}

div.Header {
  /* display: flex;
  flex-direction: row;
  position: absolute;
  top: 3%;
  width: 100%;
  height: 10%;
  background-color: black;
  color: white;
  text-align: center; */
  position: absolute;
  width: 100%;
  height: 100px;
  /* align-items: center; */
  /* justify-content: center; */
  display: flex;
  background-color: black;
  color: white;
  /* text-align: center; */
}

.home{ 
  flex: 10%;
}

h1.homeIcon {
  display: flex;
  font-size: 2em;
}


.Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4%;
  background-color: #464646;
  color: white;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .Title {
    font-size: 2.7em;
  
    margin-top: 1%;
  }
  div.Header {
    height: 80px;
  }

  .Center {
    margin-top: 100px;
  }
}

.FTitle {

  font-size: 1em;

  padding-top: 1%;
}

.Center {
  width: 70%;
  margin-top: 40px;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  /*border: 2px solid red;*/
}

.Btn-Green {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 0.3em 2em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2em;
  margin-right: 0.5%;
  justify-content: center;
  align-items: center;
  padding-top: 5;
}

.Btn-Green:hover {
  background-color: grey;
}

.Btn-Submit {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 0.3em 2em;
  text-align: center;
  text-decoration: none;
  font-size: 2em;
  margin-right: 0.5%;
  justify-content: center;
  align-items: center;
  padding-top: 5;
}

.Btn-Submit:hover {
  background-color: grey;
}

.Field {
  width: 99%;
  height: 50px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 0.5px solid grey;
  font-size: 2em;
}

@media screen and (max-width: 600px) {
  .Field {
    font-size: 1.5em;
  }
}
.homeLink {
  text-decoration: none;
  color: white;

}
.Link {
  color: #007bff;
  text-decoration: underline;
  font-size: 3em;
  background: none;
  border: none;
  cursor: pointer;
}

.Link:active {
  color: #4fa2fc;
  text-decoration: underline;
  font-size: 2em;
  background: none;
  border: none;
  /*color: red;*/
}

.Field-Heading {
  font-size: 1.5em;
  color: rgb(92, 92, 92);
  margin-bottom: 20px;
}

.canvas {
  border: 1px solid black;
  background-color: white;
}
