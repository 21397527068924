@import url("https://fonts.googleapis.com/css?family=Boogaloo&display=swap");

body,
body * {
  font-family: "Boogaloo", cursive;
}

div.box-border {
  display: flex;
  width: 90%;
  height: auto;
  margin-top: 50px;
  margin-left: 5%;
  padding: 20px;
  align-self: center;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  border-top: 11px solid #838383;
  border-left: 7px solid #838383;
  border-right: 7px solid #838383;
}

h3.text1 {
  font-size: 30;
  margin-top: 30px;
}
.box2 {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  /* width: 430px; */
  padding: 10px;
  /* height: 70px; */
  border: 6px solid #838383;
  border-radius: 23px;
  background-color: #fdffc1;
  align-items: center;
}

img.img1 {
  width: 70%;
  background-color: #fff;
  height: auto;
}

.Home-bkg {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  background-color: cyan;
}

.Header {
  position: absolute;
  /* top: 20px; */
  width: 100%;
  height: 100px;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: black;
  color: white;
  text-align: center;
}
div.HeaderNew {
  /* position: absolute; */
  display: flex;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  text-align: center;
}
h1.homeIcon {
  float: left;
  margin-top: 0.2em;
  font-size: 3em;
  position: absolute;
}

.FTitle {
  /*font-family: "Times New Roman", Times, serif;*/
  font-size: 1em;
  /*bottom: %;*/
  padding-top: 1%;
}

.Center {
  width: 70%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  /*border: 2px solid red;*/
}

.Btn-Green {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 0.3em 2em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2em;
  margin-right: 0.5%;
  justify-content: center;
  align-items: center;
  padding-top: 5;
}

.Btn-Green:hover {
  background-color: grey;
}

.Btn-Submit {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 0.3em 2em;
  text-align: center;
  text-decoration: none;
  font-size: 2em;
  margin-right: 0.5%;
  justify-content: center;
  align-items: center;
  padding-top: 5;
}

.Btn-Submit:hover {
  background-color: grey;
}

.Field {
  width: 99%;
  height: 50px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 0.5px solid grey;
  font-size: 2em;
}
@media screen and (max-width: 600px) {
  .TitleNew {
    font-size: 2.7em;
    /* margin-bottom: 10%; */
    /*margin-top: 1%;*/
  }
  .box2 {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 10px;
    border: 6px solid #838383;
    border-radius: 23px;
    background-color: #fdffc1;
    align-items: center;
  }
}

@media screen and (min-width: 700px) {
  .TitleNew {
    font-size: 4.5em;
  }
}

@media screen and (max-width: 600px) {
  .Center {
    margin-top: 55px;
  }
  img.img1 {
    width: 85%;
    height: auto;
  }
  .Field {
    font-size: 1.5em;
  }
  div.box-border {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 600px) {
  /* img.img1 {
    width: 80%;
    height: auto;
  } */
}

.Field-Heading {
  font-size: 1.5em;
  color: rgb(92, 92, 92);
}

.canvas {
  border: 1px solid black;
  background-color: white;
}
