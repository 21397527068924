@import url(https://fonts.googleapis.com/css?family=Boogaloo&display=swap);
@import url(https://fonts.googleapis.com/css?family=Boogaloo&display=swap);
@import url(https://fonts.googleapis.com/css?family=Boogaloo&display=swap);
@import url(https://fonts.googleapis.com/css?family=Boogaloo&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,800,700,300);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body,
body * {
  font-family: "Boogaloo", cursive;
}

.Home-bkg {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  /* width: 100%; */
  width: 100vw;
  background-color: cyan;
}

div.Header {
  /* display: flex;
  flex-direction: row;
  position: absolute;
  top: 3%;
  width: 100%;
  height: 10%;
  background-color: black;
  color: white;
  text-align: center; */
  position: absolute;
  width: 100%;
  height: 100px;
  /* align-items: center; */
  /* justify-content: center; */
  display: flex;
  background-color: black;
  color: white;
  /* text-align: center; */
}

.home{ 
  flex: 10% 1;
}

h1.homeIcon {
  display: flex;
  font-size: 2em;
}


.Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4%;
  background-color: #464646;
  color: white;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .Title {
    font-size: 2.7em;
  
    margin-top: 1%;
  }
  div.Header {
    height: 80px;
  }

  .Center {
    margin-top: 100px;
  }
}

.FTitle {

  font-size: 1em;

  padding-top: 1%;
}

.Center {
  width: 70%;
  margin-top: 40px;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  /*border: 2px solid red;*/
}

.Btn-Green {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 0.3em 2em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2em;
  margin-right: 0.5%;
  justify-content: center;
  align-items: center;
  padding-top: 5;
}

.Btn-Green:hover {
  background-color: grey;
}

.Btn-Submit {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 0.3em 2em;
  text-align: center;
  text-decoration: none;
  font-size: 2em;
  margin-right: 0.5%;
  justify-content: center;
  align-items: center;
  padding-top: 5;
}

.Btn-Submit:hover {
  background-color: grey;
}

.Field {
  width: 99%;
  height: 50px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 0.5px solid grey;
  font-size: 2em;
}

@media screen and (max-width: 600px) {
  .Field {
    font-size: 1.5em;
  }
}
.homeLink {
  text-decoration: none;
  color: white;

}
.Link {
  color: #007bff;
  text-decoration: underline;
  font-size: 3em;
  background: none;
  border: none;
  cursor: pointer;
}

.Link:active {
  color: #4fa2fc;
  text-decoration: underline;
  font-size: 2em;
  background: none;
  border: none;
  /*color: red;*/
}

.Field-Heading {
  font-size: 1.5em;
  color: rgb(92, 92, 92);
  margin-bottom: 20px;
}

.canvas {
  border: 1px solid black;
  background-color: white;
}

html,
body {
  font-family: "Boogaloo", cursive;
  background-color: cyan;
  height: 100%;
}

.Home-bkg1 {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  background-color: cyan;
}

.Header1 {
  position: absolute;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: black;
  color: white;
  text-align: center;
}

.home{
  flex: 10% 1;
}

h1.homeIcon {
  float: left;
  margin-top: 0.3em;
  margin-left: 0.2em;
  font-size: 2em;
  position: absolute;
}

.Footer1 {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 7%;
  background-color: #464646;
  color: white;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .Title1 {
    font-size: 2.7em;
    /* margin-bottom: 10%; */
    /*margin-top: 1%;*/
  }
}

@media screen and (min-width: 700px) {
  .Title1 {
    font-size: 4.5em;
  }
  .Header1 {
    height: 7%;
  }
}

.FTitle1 {
  /*font-family: "Times New Roman", Times, serif;*/
  font-size: 1.5em;
  /*bottom: %;*/
  padding-top: 1%;
}

.Container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Center1 {
  width: 70%;
  position: absolute;
  top: 16%;
  text-align: center;
}

.menu {
  font-size: 2em;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
}
.card {
  float: none;
  margin: 0 auto;
  width: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 1em;
  margin-bottom: 1em;
}

@media screen and (max-width: 400px) {
  .card {
    margin-bottom: 100px;
    margin-top: 0;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .Center1 {
    margin-top: 0px;
  }
}

.Btn-Green1 {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 1em 2em;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin-right: 1%;
  margin-bottom: 1em;
}

.Btn-Green1:hover {
  background-color: grey;
}

.Field1 {
  width: 60%;
  height: 70px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 0.5px solid grey;
  font-size: 2em;
}

@media screen and (max-width: 400px) {
  .Field1 {
    font-size: 1.2em;
    width: 90%;
  }
}

.Heading1 {
  font-size: 2em;
  margin-bottom: 0;
}

.Sub-Heading1 {
  font-size: 1.5em;
  margin-top: 1px;
  margin-bottom: 2.2em;
}

@media screen and (max-width: 600px) {
  .Sub-Heading1 {
    font-size: 1em;
  }
}

.Green-Bar1 {
  position: absolute;
  width: 100%;
  top: 30%;
  font-size: 3em;
  background-color: rgb(8, 199, 8);
  text-align: center;
}

@media screen and (max-width: 600px) {
  .Green-Bar1 {
    top: 27%;
  }
}

@media screen and (min-width: 700px) {
  .Spacer1 {
    /*border: 2px solid red;*/
    height: 130px;
    top: 40%;
    font-size: 3em;
  }
}

@media screen and (max-width: 600px) {
  .Spacer1 {
    /*border: 2px solid red;*/
    height: 40px;
    top: 40%;
    font-size: 3em;
  }
}

.Field-Heading1 {
  font-size: 1.5em;
  margin-top: 10px;
}

.container1 {
  /*display: block;*/
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Btn-Submit1 {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 1em 1em;
  margin: none;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  margin-bottom: 1em;
  font-size: 2em;
  margin-top: 5px;
  line-height: 5px;
}

.Table {
  background-color: #ee959e;
  width: 120%;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  -webkit-margin-start: -10%;
          margin-inline-start: -10%;
  margin-bottom: 10px;
  text-align-last: center;
}
.Table-Name {
  text-align: left;
  width: 50%;
}
.Table-Answer {
  width: 10%;
}
.Table-Challenge {
  width: 10%;
}
.player {
  text-align: left;
  width: 30%;
}

.answer {
  width: 40%;
}
.score {
  width: 10%;
}

.Table-Entry {
  background-color: #f4c2c2;
  font-size: 1.5em;
  -webkit-margin-end: auto;
          margin-inline-end: auto;
  margin-right: 0.8em;
  margin-left: 20px;
  margin-bottom: 5px;
  margin-top: 2px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  /*top: 0;
  left: 0;*/
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.homeLink {
  text-decoration: none;
  color: white;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.Timer {
  margin-left: 37%;
}
@media screen and (max-width: 400px) {
  .Timer {
    margin-left: 15%;
  }
}

.question-box {
  background-color: white;
  padding: 10px 20px;
  border: 10px solid rgb(253, 145, 102);
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.Die {
  font-size: 8.2em;
  background: white;
  color: rgb(205, 90, 100);
  border-radius: 100px;
  line-height: 69px;
  margin-left: 0.1em;
  align-self: center;
  margin-bottom: 0.5em;
}

@media screen and (max-width: 400px) {
  .Die {
    font-size: 7em;
  }
}

.shaking {
  -webkit-animation-name: wobble;
          animation-name: wobble;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

@-webkit-keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-65%, 0, 0) rotate3d(0, 0, 1, -20deg);
  }

  30% {
    transform: translate3d(50%, 0, 0) rotate3d(0, 0, 1, 18deg);
  }

  45% {
    transform: translate3d(-45%, 0, 0) rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: translate3d(30%, 0, 0) rotate3d(0, 0, 1, 5deg);
  }

  75% {
    transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -4deg);
  }
  90% {
    transform: translate3d(8%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-65%, 0, 0) rotate3d(0, 0, 1, -20deg);
  }

  30% {
    transform: translate3d(50%, 0, 0) rotate3d(0, 0, 1, 18deg);
  }

  45% {
    transform: translate3d(-45%, 0, 0) rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: translate3d(30%, 0, 0) rotate3d(0, 0, 1, 5deg);
  }

  75% {
    transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -4deg);
  }
  90% {
    transform: translate3d(8%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.RollDice {
  display: flex;
  flex-flow: column nowrap;
  min-height: 50vh;
}
.RollDice-container {
  /*display: flex;*/
  justify-content: center;
  align-content: center;
}
.RollDice button {
  align-self: center;
  width: 15em;
  padding: 1.5em;
  font-size: 1.5em;
  border: 0px;
  border-radius: 10px;
  color: white;
  background-color: rgb(8, 199, 8);
  margin-bottom: 1em;
}
.spacer {
  margin-bottom: 6em;
}
.totalDice {
  font-size: 1.5em !important;
  text-align: right;
  margin-top: 1em;
  margin-right: 1em;
  color: black;
}
@media screen and (max-width: 400px) {
  .spacer {
    margin-bottom: 4em;
  }
  .totalDice {
    margin-top: -1.5em;
  }
}

.trashIcon {
  padding: 0.5em;
  font-size: 6em;
  margin-bottom: 20px;
  margin-top: 25px;
}

.RollDice .resetButton {
  background-color: black;
  padding: 0.5em;
}

.RollDice button:hover {
  background-color: darkslateblue;
  cursor: pointer;
}
.highlighted .box {
  -ms-transform: scale(1.5);
      transform: scale(1.5);
}
.RollDice .center-modal {
  align-self: center;
}
.RollDice .yes-button {
  background-color: rgb(8, 199, 8);
  padding: 0.5em;
  margin-right: 20px;
  width: 3em;
}
.RollDice .no-button {
  background-color: rgb(240, 56, 50);
  padding: 0.5em;
  margin-right: 20px;
  width: 3em;
}

body,
body * {
  font-family: "Boogaloo", cursive;
}

.Home-bkg {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  background-color: cyan;
}

.Header {
  position: absolute;
  top: 3%;
  width: 100%;
  height: 10%;
  background-color: black;
  color: white;
  text-align: center;
}
h1.homeIcon {
  float: left;
  margin-top: 0.2em;
  font-size: 3em;
  position: absolute;
}

.Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4%;
  background-color: #464646;
  color: white;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .Title {
    font-size: 3em;
    margin-bottom: 10%;
    /*margin-top: 1%;*/
  }
}

@media screen and (min-width: 700px) {
  .Title {
    font-size: 5em;
  }
}

.FTitle {
  /*font-family: "Times New Roman", Times, serif;*/
  font-size: 1em;
  /*bottom: %;*/
  padding-top: 1%;
}
.white-board-title {
  text-align: center;
  position: absolute;
  margin-left: 15%;
  margin-top: 25%;
}

.center-whiteboard {
  background-color: white;
  width: 300px;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  /*border: 2px solid red;*/
}

@media screen and (max-width: 600px) {
  .Center {
    margin-top: 55px;
  }
}

.Btn-Green {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 0.3em 2em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2em;
  margin-right: 0.5%;
}

.Btn-Green:hover {
  background-color: grey;
}

.Field {
  width: 99%;
  height: 50px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 0.5px solid grey;
  font-size: 2em;
}

@media screen and (max-width: 600px) {
  .Field {
    font-size: 1.5em;
  }
}
.homeLink {
  text-decoration: none;
  color: white;
}
.Link {
  color: #007bff;
  text-decoration: underline;
  font-size: 3em;
  background: none;
  border: none;
  cursor: pointer;
}

.Link:active {
  color: #4fa2fc;
  text-decoration: underline;
  font-size: 3em;
  background: none;
  border: none;
  /*color: red;*/
}

.Field-Heading {
  font-size: 1.5em;
  color: rgb(92, 92, 92);
}

.canvas {
  border: 1px solid black;
  background-color: white;
}

body,
html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  /*background-color: yellow; */
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

#container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  min-height: 100%;
  width: 0%;
  height: 100%;
  background-color: #607d8b;
  color: #000000;
}

.canvas-area {
  margin: 7px 0 0 7px;
  border: 1px solid #0d3349;
  background-color: #ffffff;
}

.tool-button {
  min-width: 120;
}

body,
body * {
  font-family: "Boogaloo", cursive;
}

div.box-border {
  display: flex;
  width: 90%;
  height: auto;
  margin-top: 50px;
  margin-left: 5%;
  padding: 20px;
  align-self: center;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  border-top: 11px solid #838383;
  border-left: 7px solid #838383;
  border-right: 7px solid #838383;
}

h3.text1 {
  font-size: 30;
  margin-top: 30px;
}
.box2 {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  /* width: 430px; */
  padding: 10px;
  /* height: 70px; */
  border: 6px solid #838383;
  border-radius: 23px;
  background-color: #fdffc1;
  align-items: center;
}

img.img1 {
  width: 70%;
  background-color: #fff;
  height: auto;
}

.Home-bkg {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  background-color: cyan;
}

.Header {
  position: absolute;
  /* top: 20px; */
  width: 100%;
  height: 100px;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: black;
  color: white;
  text-align: center;
}
div.HeaderNew {
  /* position: absolute; */
  display: flex;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  text-align: center;
}
h1.homeIcon {
  float: left;
  margin-top: 0.2em;
  font-size: 3em;
  position: absolute;
}

.FTitle {
  /*font-family: "Times New Roman", Times, serif;*/
  font-size: 1em;
  /*bottom: %;*/
  padding-top: 1%;
}

.Center {
  width: 70%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  /*border: 2px solid red;*/
}

.Btn-Green {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 0.3em 2em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2em;
  margin-right: 0.5%;
  justify-content: center;
  align-items: center;
  padding-top: 5;
}

.Btn-Green:hover {
  background-color: grey;
}

.Btn-Submit {
  background-color: rgb(8, 199, 8); /* Green */
  border: none;
  color: white;
  padding: 0.3em 2em;
  text-align: center;
  text-decoration: none;
  font-size: 2em;
  margin-right: 0.5%;
  justify-content: center;
  align-items: center;
  padding-top: 5;
}

.Btn-Submit:hover {
  background-color: grey;
}

.Field {
  width: 99%;
  height: 50px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 0.5px solid grey;
  font-size: 2em;
}
@media screen and (max-width: 600px) {
  .TitleNew {
    font-size: 2.7em;
    /* margin-bottom: 10%; */
    /*margin-top: 1%;*/
  }
  .box2 {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 10px;
    border: 6px solid #838383;
    border-radius: 23px;
    background-color: #fdffc1;
    align-items: center;
  }
}

@media screen and (min-width: 700px) {
  .TitleNew {
    font-size: 4.5em;
  }
}

@media screen and (max-width: 600px) {
  .Center {
    margin-top: 55px;
  }
  img.img1 {
    width: 85%;
    height: auto;
  }
  .Field {
    font-size: 1.5em;
  }
  div.box-border {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 600px) {
  /* img.img1 {
    width: 80%;
    height: auto;
  } */
}

.Field-Heading {
  font-size: 1.5em;
  color: rgb(92, 92, 92);
}

.canvas {
  border: 1px solid black;
  background-color: white;
}

.app {
  margin: 20px;
}
body {
  padding: 20px 80px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  margin-top: 10%;
  margin-left: 45%;
}

.spinner-actual {
  width: 50px !important;
  height: 50px !important;
}

